<template>
    <v-app-bar style="background:#6680b0 !important;" height="54px" :clipped-left="$vuetify.breakpoint.smAndDown" :fixed="$vuetify.breakpoint.smAndUp"
               color="primary" app dark>
        <v-img src="/img/logo.svg" max-height="40px" position="left left" contain></v-img>
        <v-divider vertical dark class="pr-2"></v-divider>
        <Languages></Languages>
    </v-app-bar>
</template>
<script>

import Languages from "./Languages"

export default {
    name: "LoginTopMenu",
    components: {
        Languages,
    },
    props: {
        mini: Boolean,
        drawer: Boolean,
    },
    data() {
        return {
            isFullscreen: false,
        }
    },
    computed: {

    },
    methods: {



        toggleFullscreen: function (event) {
            var elem = document.documentElement
            if (
                document.fullscreenEnabled ||
                document.webkitFullscreenEnabled ||
                document.mozFullScreenEnabled ||
                document.msFullscreenEnabled
            ) {
                if (!this.isFullscreen) {
                    if (elem.requestFullscreen) {
                        elem.requestFullscreen()
                        this.isFullscreen = true
                        return
                    } else if (elem.webkitRequestFullscreen) {
                        elem.webkitRequestFullscreen()
                        this.isFullscreen = true
                        return
                    } else if (elem.mozRequestFullScreen) {
                        elem.mozRequestFullScreen()
                        this.isFullscreen = true
                        return
                    } else if (elem.msRequestFullscreen) {
                        elem.msRequestFullscreen()
                        this.isFullscreen = true
                        return
                    }
                } else {
                    if (document.exitFullscreen) {
                        document.exitFullscreen()
                        this.isFullscreen = false
                        return
                    } else if (document.webkitExitFullscreen) {
                        document.webkitExitFullscreen()
                        this.isFullscreen = false
                        return
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen()
                        this.isFullscreen = false
                        return
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen()
                        this.isFullscreen = false
                        return
                    }
                }
            }
        },
    }
}
</script>
