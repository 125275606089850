import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// eslint-disable-next-line no-sparse-arrays
let routes = [
    {
        path: '/',
        redirect: '/orders',
    },
    {
        path: '/orders',
        name: 'orders',
        meta: {layout: 'main', auth: {roles: ['wms']}},
        component: () => import(/* webpackChunkName: "orders" */ '../views/Orders.vue'),
    },
    {
        path: '/order/create',
        name: 'order.create',
        meta: {layout: 'main', auth: {roles: ['wms']}},
        component: () => import(/* webpackChunkName: "order-form" */ '../views/OrderForm.vue'),
    },
    {
        path: '/order/:id/edit',
        name: 'order.edit',
        meta: {layout: 'main', auth: {roles: ['wms']}},
        component: () => import(/* webpackChunkName: "order-form" */ '../views/OrderForm.vue'),
    },
    {
        path: '/category/:id/:name',
        name: 'category.products',
        meta: {layout: 'main', auth: {roles: ['wms']}},
        component: () => import(/* webpackChunkName: "invoice-form" */ '../views/Products.vue'),
    },

    {
        path: '/profile',
        name: 'profile',
        meta: {layout: 'main', auth: {roles: ['wms']}},
        component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileForm.vue'),
    },
    {
        path: '/notifications',
        name: 'notifications',
        meta: {layout: 'main', auth: {roles: ['wms']}},
        component: () => import(/* webpackChunkName: "Notifications" */ '../views/Notifications.vue'),
    },



    {
        path: '/auth/login',
        name: 'login',
        meta: { layout: 'login-page', auth: false },
        component: () => import(/* webpackChunkName: "login" */ '../views/Auth/Login.vue'),
    },
    {
        path: '/auth/register',
        name: 'register',
        meta: {layout: 'login-page', auth: false},
        component: () => import(/* webpackChunkName: "register" */ '../views/Auth/Login.vue'),
    },
    {
        path: '/auth/forgot',
        name: 'forgot',
        meta: {layout: 'login-page', auth: false},
        component: () => import(/* webpackChunkName: "forgot" */ '../views/Auth/Forgot.vue'),
    },,
    {
        path: '/auth/forgot/check',
        name: 'reset_password',
        meta: {layout: 'login-page', auth: false},
        component: () => import(/* webpackChunkName: "reset-password" */ '../views/Auth/ResetPassword.vue'),
    },
    {
        path: '/404',
        name: 'error-404',
        meta: {layout: 'main', auth: true},
        component: () => import(/* webpackChunkName: "not-found" */ '../views/Errors/NotFound.vue'),
    },
    {
        path: '/403',
        name: 'error-403',
        meta: {layout: 'main', auth: true},
        component: () => import(/* webpackChunkName: "forbidden" */ '../views/Errors/Forbidden.vue'),
    },
    {
        path: '/block',
        name: 'block',
        meta: {layout: 'single-page', auth: undefined},
        component: () => import(/* webpackChunkName: "blocked" */ '../views/Errors/Blocked.vue'),
    },
];


const router = new VueRouter({
    scrollBehavior() {
        return window.scrollTo({top: 0, behavior: 'smooth'});
    },
    hashbang: false,
    linkActiveClass: 'active',
    transitionOnLoad: true,
    mode: 'history',
    base: __dirname,
    routes,
});

export default router;
