import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/messaging'

firebase.initializeApp({
    apiKey: "AIzaSyAId2dNcpGlWS1R0DB7adDHH6rrkWSj8j0",
    authDomain: "atasuexpress-fa454.firebaseapp.com",
    databaseURL: "https://atasuexpress-fa454.firebaseio.com",
    projectId: "atasuexpress-fa454",
    storageBucket: "atasuexpress-fa454.appspot.com",
    messagingSenderId: "560286467680",
    appId: "1:560286467680:web:ec54f0ec1ccefbcf0ba505",
    measurementId: "G-V5DKEW3YRC"


});

if (firebase.messaging.isSupported()) {

    window.messaging = firebase.messaging()
    //window.messaging.usePublicVapidKey("AAAAlnutJQY:APA91bEhBXhn7bnf3Wtghc1VuNA_mGDNhsX9tIMNqFxdVDNCQ2-HCqG-I9DiO3Ey6QJGlHl5u95XN3W3KRYL5cGQtnJInRmUbOELMeToRgXJyiDbACL89e_iHPTEI6yaGKB2ajGAtD8N")

    navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
            window.messaging.useServiceWorker(registration)
        }).catch(err => {
        console.log(err)
    })

} else {

    console.log("Browser doesn't support FCM")
}
