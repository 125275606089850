const locaCache = {
    get order() {
        return JSON.parse(localStorage.getItem('orderCache')) ?? []
    },
    set order(value) {
        localStorage.setItem('orderCache',  JSON.stringify(value))
    }
};
export default locaCache;

