<template>
    <v-footer color="grey lighten-3" inset absolute app>
        {{ $t('footer_text', {year: new Date().getFullYear()}) }}
    </v-footer>
</template>

<script>
export default {
    name: "app-footer"
};
</script>

<style scoped>
</style>
