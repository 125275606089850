//import bearer from '@websanova/vue-auth/drivers/auth/bearer'
import bearer from './bearer'
import resource from '@websanova/vue-auth/drivers/http/vue-resource.1.x.js'
import router from '@websanova/vue-auth/drivers/router/vue-router.2.x'

const config = {

    auth: bearer,
    http: resource,
    router: router,
    tokenDefaultKey: 'authToken',
    stores: ['storage'],
    authRedirect: {path: '/auth/login'},
    forbiddenRedirect: {path: '/403'},
    notFoundRedirect: {path: '/404'},
    rolesVar: 'roles',

    registerData: {
        url: 'auth/wms/register',
        method: 'POST',
        redirect: '/auth/login',
        enabled: false
    },
    loginData: {
        url: 'auth/wms/login',
        method: 'POST',
        redirect: '/orders',
        fetchUser: true,
        staySignedIn: true
    },
    logoutData: {
        url: 'auth/wms/logout',
        method: 'POST',
        redirect: '/auth/login',
        makeRequest: true,
    },
    fetchData: {
        url: 'auth/wms/user',
        method: 'POST',
        enabled: true,
    },
    refreshData: {
        url: 'auth/wms/refresh',
        headers: {'Cache-Control': 'no-cache, no-store'},
        method: 'POST',
        enabled: true,
        interval: 10,
    }
};

export default config
