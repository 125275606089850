<template>
    <v-navigation-drawer v-model="drawerShow" :clipped="$vuetify.breakpoint.smAndDown" :mini-variant="mini"
                         :mini-variant.sync="isMini" :expand-on-hover="mini" mobile-breakpoint="960"
                         :width="$vuetify.breakpoint.smAndDown ? '280' : '350'" mini-variant-width="80" height="100%"
                         light app floating>
        <v-list class="py-0 comany-logo" color="primary" dark style="border:0;border-radius:0;height:64px;background:#6680b0 !important;">
            <v-list-item>
                <v-list-item-content v-if="($vuetify.breakpoint.smAndDown && mini) || isMini">
                    <v-img src="/img/logo-small.png" max-height="40px" contain></v-img>
                </v-list-item-content>
                <v-list-item-content v-else>
                    <v-img src="/img/logo.svg" max-height="40px" contain></v-img>
                </v-list-item-content>
                <v-list-item-icon v-if="$vuetify.breakpoint.smAndDown">
                    <v-btn small icon @click.stop="navToggle" class="mx-0">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
        <v-divider class="my-0"></v-divider>
        <v-sheet class="pb-0 pa-2 primary">
            <v-text-field
                v-model="search"
                :label="$t('search_category')"
                dark
                flat
                solo-inverted
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
            ></v-text-field>
        </v-sheet>
        <scrollbar ref="scrollbar"
                   :swicher="!$vuetify.breakpoint.smAndDown"
                   :settings="settingsScrollbar"
                   class="inner-scrollbar">
            <v-list class="py-0 section_flex"  v-for="(group, index) in items" :key="index">
                <template v-for="item in group">
                    <v-subheader v-if="item.header" v-show="item.visible">{{ $t(item.title) }}</v-subheader>
                    <v-list-group v-else-if="!!item.items" v-show="item.visible" :prepend-icon="item.icon" no-action
                                  :key="index" v-model="item.active">
                        <template v-slot:activator>
                            <v-list-item-content class="nav-bar-content" >
                                <v-list-item-title >{{ $t(item.title) }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item v-for="(subItem,s) in item.items" :key="s" v-show="subItem.visible"
                                     @click.native="subItem.action ? subItem.action() : false" :to="subItem.to" ripple
                                     :exact="subItem.exact !== undefined ? subItem.exact : true"
                                     color="#ffffff" dark>
                            <v-list-item-icon>
                                <v-icon>{{ subItem.icon }}1</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="pl-2 nav-bar-content">
                                <v-list-item-title class="text--white">{{ $t(subItem.title) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item v-else v-show="item.visible" @click.native="item.action ? item.action() : false"
                                 href="javascript:void(0)" :to="item.to" ripple
                                 :exact="item.exact !== undefined ? item.exact : true" :key="item.title"
                                 color="#ffffff" dark class="list-item" exact-active-class="list-item-active">
                        <v-list-item-icon v-if="item.icon">
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="nav-bar-content">
                            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>

            </v-list>
            <template v-if="!isMini">
                <v-card-text class="primary py-0 pa-0">
                    <v-treeview
                        @update:active="openCategoryProduct"
                        return-object
                        item-key="id"
                        hoverable
                        activatable
                        color="white"
                        :items="categories"
                        :search="search"
                        :filter="filter"
                        :open.sync="open"
                        open-on-click
                        :open-all="open_all"
                        class="pa-0 ma-0 ml-0 white--text text-white dimas"
                        expand-icon=""
                    >
                        <template slot="append" slot-scope="{ item }">
                            {{ item.count }}
                        </template>
                        <template v-slot:label="{item}">
                            <v-list-item-content class="pa-0 ma-0 ml-0 nav-bar-content">
                                <v-list-item-title class="pa-0 ma-0 ml-0">
                                    {{ item.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                    </v-treeview>
                </v-card-text>
            </template>
        </scrollbar>
    </v-navigation-drawer>
</template>
<script>

import Scrollbar from './Scrollbar'
import {mapGetters} from "vuex";
//import {mapGetters} from "vuex";

export default {
    name: "AppSidebar",
    components: {
        Scrollbar,
    },
    inject: ['forceRerender'],
    props: {
        mini: Boolean,
        drawer: Boolean
    },
    data() {
        return {
            isMini: null,
            items: [],
            settingsScrollbar: {
                suppressScrollX: true,
            },
            open_all: false,
            categories: [],
            open: ['2de700f7-aacd-11ec-a1e2-002590da84b1'],
            search: null,
            caseSensitive: false,
        };
    },
    computed: {
        ...mapGetters(['orderCache']),
        filter () {
            return this.caseSensitive
                ? (categories, search, textKey) => categories[textKey].indexOf(search) > -1
                : undefined
        },
        drawerShow: {
            get: function () {
                return this.drawer;
            },
            set: function (state) {
                if (state !== this.drawer) {
                    this.navToggle();
                }
            }
        },
        expandOnHover: function () {
            return this.mini;
        },
        routePath() {
            return this.$route.path;
        },
    },
    watch: {
        routePath(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.navigation()
            }
        },
    },
    mounted() {
        this.navigation();
        this.getCategories();


    },
    methods: {
        navToggle() {
            this.$emit("nav-toggle");
        },
        navMiniToggle() {
            this.$emit("nav-mini-toggle");
        },
        navigation() {
            this.items = [
                [
                    {
                        title: 'place_an_order',
                        icon: false,
                        to: {name: "order.create"},
                        action: this.forceRerender,
                        visible: (this.can(['wms']) && this.orderCache.length > 0),
                        exact: false
                    },
                    {
                        title: 'menu_applications',
                        icon: false,
                        to: {name: "orders"},
                        action: this.forceRerender,
                        visible: this.can(['wms']),
                        exact: false
                    },
                ]
            ];
        },
        openCategoryProduct(item){
            this.$router.push({
                name: 'category.products',
                params: {
                    id: item[0].id,
                    name: item[0].name
                }
            })
            this.forceRerender()
        },
        async getCategories() {
            var _this = this
            this.loading = true;
            let params = {}
            if (this.lang) {
                params.language = this.lang
            }
            await this.$http
                .get("wms/category", {
                    params: params,
                })
                .then(res => {
                    this.categories = res.body.data
                    this.open_all = false
                })
                .catch(err => {
                    this.categories = []
                    this.$toastr.error(this.$t('failed_to_get_list_categories'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
<style >

div.section_flex{
    //display: flex;
    //justify-content: center;
    //align-items: center;
    //width: auto;
    //flex-wrap: wrap;

}
a.list-item{
    background-color: #45649f !important;
}
a.list-item::after{
    background-color: #45649f !important;
}
a.list-item::before{
    background-color: #45649f !important;
}

.v-treeview.dimas .v-icon{
    display: none;
}

.v-treeview.dimas > .v-treeview-node--leaf .v-treeview-node__level{
    display: none;
}

.v-treeview.dimas .v-treeview-node--leaf .v-treeview-node__level{
    width: 10px;
}

.v-treeview.dimas .v-treeview-node__content{
    margin-left: 0;
}



</style>
